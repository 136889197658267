<template>
	<path id="Vector" d="M6 150.74L43.3699 76H143.114L180.484 150.74L143.114 225.48H43.3699L6 150.74Z" fill="#D4A168" />
	<path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
		d="M39.8699 71H145.794L185.664 150.74L145.794 230.48H39.8699L0 150.74L39.8699 71ZM46.0503 81L11.1803 150.74L46.0503 220.48H139.614L174.484 150.74L139.614 81H46.0503Z"
		fill="#D4A168" />
	<path id="Vector_2" d="M6 299.74L43.3699 225H143.114L180.484 299.74L143.114 374.48H43.3699L6 299.74Z" fill="#D4A168" />
	<path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
		d="M39.8699 220H145.794L185.664 299.74L145.794 379.48H39.8699L0 299.74L39.8699 220ZM46.0503 230L11.1803 299.74L46.0503 369.48H139.614L174.484 299.74L139.614 230H46.0503Z"
		fill="#D4A168" />
	<path id="Vector_3" d="M6 448.74L43.3699 374H143.114L180.484 448.74L143.114 523.48H43.3699L6 448.74Z" fill="#D4A168" />
	<path id="Vector (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd"
		d="M39.8699 369H145.794L185.664 448.74L145.794 528.48H39.8699L0 448.74L39.8699 369ZM46.0503 379L11.1803 448.74L46.0503 518.48H139.614L174.484 448.74L139.614 379H46.0503Z"
		fill="#D4A168" />
</template>